import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
  selector: "app-partenaires",
  templateUrl: "./partenaires.component.html",
  styleUrls: ["./partenaires.component.css"]
})
export class PartenairesComponent implements OnInit, OnDestroy {
  header = document.getElementsByTagName("header")[0];
  selectedItemsArr: any = [0];

  constructor() {}

  ngOnInit() {
    this.header.style.maxHeight = "450px";
  }

  ngOnDestroy() {
    this.header.style.maxHeight = "80vh";
  }

  pushToSelectedItems(id: number) {
    let split: number = -1;
    //si on trouve l'id dans l'array, split = l'index de l'array
    for (let i = 0; i < this.selectedItemsArr.length; i++) {
      if (this.selectedItemsArr[i] === id) {
        split = i;
      }
    }
    //si on a trouvé l'id,on le supprime, sinon on l'ajoute
    if (split !== -1) {
      this.selectedItemsArr.splice(split, 1);
    } else {
      this.selectedItemsArr.push(id);
    }
  }

  //indique pour un ngif si l'element doit s'afficher commme étant actif
  isActiveCategory(id: number, isDiv: boolean) {
    let value = false;
    for (let i = 0; i < this.selectedItemsArr.length; i++) {
      if (this.selectedItemsArr[i] === id) {
        value = true;
      }
    }
    //  si l'element apppelant la fonction est une div et qu'aucun click n'a été fait
    if (isDiv && this.selectedItemsArr.length === 1) {
      value = true;
    }
    return value;
  }
}
