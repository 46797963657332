import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-prestations',
  templateUrl: './prestations.component.html',
  styleUrls: ['./prestations.component.css']
})
export class PrestationsComponent implements OnInit, OnDestroy {

  header = document.getElementsByTagName("header")[0];

  constructor() { }

  ngOnInit() {
    this.header.style.maxHeight = "450px";
  }

  ngOnDestroy() {
    this.header.style.maxHeight = "80vh";
  }

}
