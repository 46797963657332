import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cookies",
  templateUrl: "./cookies.component.html",
  styleUrls: ["./cookies.component.css"]
})
export class CookiesComponent implements OnInit {
  htmlChecked: boolean;
  cookiesBar: HTMLElement;

  constructor() {}

  ngOnInit() {
    this.cookiesBar = document.getElementById("cookieBar");
    var cookiesAccepted = null;

    if (sessionStorage.getItem("cookiesAccepted")) {
      cookiesAccepted = true;
      this.CookiesAreAccepted(cookiesAccepted);
    } 
    // else {
    //   sessionStorage.setItem("cookiesAccepted", "null");
    // }
  }

  CookiesAreAccepted(param) {
    if (param) {
      sessionStorage.setItem("cookiesAccepted", "true");
      this.cookiesBar.style.display = "none";
    } 
    else {
      // sessionStorage.setItem("cookiesAccepted", "false");
      this.cookiesBar.style.display = "none";
      document.getElementById("cookiesAccept").remove();
      document.getElementById("cookiesScript").remove();
    }
  }
  // ClearCookieschoices() {
  //   sessionStorage.removeItem("cookiesAccepted");
  // }
}
