import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit, OnDestroy {
  header = document.getElementsByTagName("header")[0];
  selectedItemsArr: any = [0];

  constructor() {}

  ngOnInit() {
    this.header.style.maxHeight = "80vh";
  }

  ngOnDestroy() {
    this.header.style.maxHeight = "450px";
  }

}
