import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgForm, FormControl, Validators } from "@angular/forms";
import { HttpClient, HttpResponse } from '@angular/common/http';

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"]
})
export class ContactComponent implements OnInit, OnDestroy {
  header = document.getElementsByTagName("header")[0];
  siteID: number = 13;

  checkbox: boolean = false;
  email: string;
  name: string;
  prenom: string;
  address: string;
  codePostal: number;
  ville: string;
  subject: string;
  message: string;
  phone: FormControl;
  urlCrypto: string = "https://ws-gbbu02.graphibox.eu/WebService.asmx/ToolsSymetricEncrypt";
  apiKey: string = "LQDbm29UGRcCUm9nts8FqfP8GFJCm3u3"
  urlDb: string = 'https://ws-gbbu02.graphibox.eu/WebService.asmx/SendContact'

  mailSend: boolean = false;
  errorMessage: string = "";

  ip: string;
  browser: string;

  constructor(private http: HttpClient) {
  }
  ngOnInit(){
    this.header.style.maxHeight = "450px";
  } 

  ngOnDestroy() {
    this.header.style.maxHeight = "80vh";
  }

  onSubmit(form: NgForm) {
    this.mailSend = false;
    this.checkbox = form.value.rgpd;
    console.log(this.checkbox)

    this.email = form.value.email.replace(/\|/g, '_');
    this.name = form.value.name.replace(/\|/g, '_');
    this.prenom = form.value.prenom.replace(/\|/g, '_');
    this.address = form.value.address.replace(/\|/g, '_');
    this.codePostal = form.value.codePostal.replace(/\|/g, '_');
    this.ville = form.value.ville.replace(/\|/g, '_');
    this.subject = form.value.subject.replace(/\|/g, '_');
    this.message = form.value.message.replace(/\|/g, '_');
    this.phone = form.value.phone.replace(/\|/g, '_');

    if (form.valid) {
      this.sendEmail(form);
    }
  }

  sendEmail(form) {
    let myStr = 'ASV85|';
    myStr +=      this.name       +'|'
                + this.prenom     +'|'
                + this.phone      +'|'
                + this.email      +'|'
                + this.address    +'|'
                + this.ville      +'|'
                + this.codePostal +'|||'
                + this.subject    +'|'
                + this.message 
                + '|1|1|'         
                + this.browser    +'|' 
                + this.ip         +'|'
                + this.siteID
                + '|';

    fetch(this.urlCrypto, {
      method : "POST",
      mode: 'cors',
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
        pKey:this.apiKey,
        pDatas:myStr,
        })
    })
    .then(function(res) {
      return res.json();
    })
    .then((res) => {
      if (res.d != '') {        
        this.sendCryptedValue(JSON.parse(res.d), form)
      }
    });
  }

  sendCryptedValue(cryptedValue, form){    
    let body = JSON.stringify({
      pKey:this.apiKey,
      pType:'web-contact',
      pDatas:cryptedValue,
      })

    fetch(this.urlDb, {
      method : "POST",
      mode: 'cors',
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: body
    })
    .then(function(res) {
      return res.json();
    })
    .then( function(res) {
      console.log(res.d)
      form.reset();
    });

    this.mailSend = true;
  }

  getIPAddress()
    {
      this.http.get("http://ws-gbbu02.graphibox.eu/GetIP.aspx").subscribe((res:any)=>{
        this.ip = res.IP;
        this.browser = res.BROWSER
      });
    }

    // update(form: NgForm){
    //   console.log(form)
    //   this.checkbox = form.value.rgpd;
    //   console.log(this.checkbox)
    // }
}
