import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-nouveaute',
  templateUrl: './nouveaute.component.html',
  styleUrls: ['./nouveaute.component.css']
})
export class NouveauteComponent implements OnInit, OnDestroy {
  header = document.getElementsByTagName("header")[0];
  apiKey: string = "LQDbm29UGRcCUm9nts8FqfP8GFJCm3u3";
  siteID: number = 13;
  
  urlListArticle: string = "https://ws-gbbu02.graphibox.eu/WebService.asmx/GetArticles"
  urlImage: string = 'https://cdn-gbbu02.graphibox.eu/pub/' +  this.siteID + '/medias/articles/';
  articles: any[] = [];

  constructor(private http: HttpClient ) {
  }
  
  ngOnInit() {
    this.header.style.maxHeight = "450px";
    this.getArticles();
  }

  ngOnDestroy() {
    this.header.style.maxHeight = "80vh";
  }

  getArticles(qtt: number = 4) {

    fetch(this.urlListArticle, {
      method : "POST",
      mode: 'cors',
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
        pKey:this.apiKey,
        pId: 0,
        pIdCat: 0,
        pNbItems: qtt
        })
    })
    .then(function(res) {
      return res.json();
    })
    .then((res) => {
      let test = JSON.parse(res.d);
      for (let i = 0; i <= test.length; i++) {
        if (test[i]) {
            this.articles.push(test[i]);
            console.log(test[i])
        };
      }
    });
  }

  imageUrl(id: number, imageName: string) {
    return this.urlImage + id + '/' + imageName;
  } 
  
}


