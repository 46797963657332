import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ContactComponent } from "./components/contact/contact.component";
import { HomeComponent } from "./components/home/home.component";
import { Routes, RouterModule, Route } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CookiesComponent } from './components/cookies/cookies.component';
import { PrestationsComponent } from './components/prestations/prestations.component';
import { NouveauteComponent } from './components/nouveaute/nouveaute.component';
import { PartenairesComponent } from './components/partenaires/partenaires.component';
import { OrangeBottomComponent } from './components/orange-bottom/orange-bottom.component';
import { MentionlegaleComponent } from './components/mentionlegale/mentionlegale.component';

const appRoutes: Routes = [
  { path: "", component: HomeComponent },
  { path: "contact", component: ContactComponent },
  { path: "nos-prestations-piece-auto", component: PrestationsComponent },
  { path: "nouveaute-pieces-auto", component: NouveauteComponent },
  { path: "nos-partenaires-pieces-auto", component: PartenairesComponent },
  { path: "mention-legales", component: MentionlegaleComponent },
  { path: "", redirectTo: "", pathMatch: "full" },
  { path: "**", redirectTo: "" }
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    HomeComponent,
    CookiesComponent,
    PrestationsComponent,
    NouveauteComponent,
    PartenairesComponent,
    OrangeBottomComponent,
    MentionlegaleComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, { useHash: false })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
