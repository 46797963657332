import { Component, OnInit, HostListener } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Event } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  burgerMenu: boolean = false;
  fixedMenu: boolean = false;

  constructor(private router: Router) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.burgerMenu = false;
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
    doSomething(event) {
        if (window.pageYOffset > 150) {
            this.fixedMenu = true;
        } else {
            this.fixedMenu = false;
        }
    }


  ngOnInit() {}

  showBurgerMenu() {
    this.burgerMenu = !this.burgerMenu;
  }
}
