import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mentionlegale',
  templateUrl: './mentionlegale.component.html',
  styleUrls: ['./mentionlegale.component.css']
})
export class MentionlegaleComponent implements OnInit {

  clientName: string = 'ASV85';
  websiteUrl: string = 'https://asv85.fr';
  contact: string = "magasin@asv85.fr";
  clientAddress: string = "13 Chemin du Parois "
  clientCodePostal: string = "85300";
  clientCity: string = "CHALLANS"
  responsablePublication: string ='MANQUANT' ;
  raisonSociale: string = 'AUTO SPECIALITE VENDEENNE';
  telephone: string = "02 51 68 31 66";
  codeAPE: string = '4531Z '; 
  numTva: string = 'FR32409256716';

  constructor() { }

  ngOnInit() {
  }

}
